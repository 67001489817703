
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































































































































$bp: m;

.cta-gallery {
  overflow: hidden;
}

.cta-gallery-inner {
  @extend %text-center;

  position: relative;
  padding-top: 40vw;
  padding-bottom: var(--spacing-l);

  @include mq($bp) {
    padding-top: 6rem;
    padding-bottom: 15rem;
  }
}

.cta-gallery__icon {
  margin-bottom: var(--spacing-s);
  color: $c-mustard;
}

.cta-gallery__text {
  max-width: 60rem;
  margin: var(--spacing-s) auto;
}

[class*='cta-gallery__picture--'] {
  @extend %border-radius-small;

  position: absolute;
  max-width: 30rem;
  box-shadow: 0 1.6rem 5rem 0 rgba(0, 0, 0, 15%);
  will-change: transform;

  &[class*='--0'] {
    z-index: 1;
    top: 40vw;
    left: 5vw;
    width: 40vw;
    transform: translateX(-30%) rotate(-40deg);
  }

  &[class*='--1'] {
    top: 15vw;
    left: 0;
    width: 40vw;
    transform: translateX(-20%) rotate(-10deg);
  }

  &[class*='--2'] {
    top: 0;
    left: 50%;
    width: 40vw;
    transform: translateX(-50%) rotate(0deg);
  }

  &[class*='--3'] {
    top: 15vw;
    right: 5vw;
    width: 35vw;
    transform: translateX(10%) rotate(5deg);
  }

  &[class*='--4'] {
    top: 35vw;
    right: 0;
    width: 50vw;
    transform: translateX(50%) rotate(11deg);
  }

  @include mq($bp) {
    max-width: none;
    box-shadow: 0 2rem 6rem 0 rgba(0, 0, 0, 15%);

    &[class*='--0'] {
      top: 12%;
      right: col(0.5, 10);
      left: initial;
      width: 30rem;
      transform: translate(50%, -50%) rotate(3deg);
    }

    &[class*='--1'] {
      top: 30%;
      right: col(0.25, 10);
      left: initial;
      width: 27rem;
      transform: translate(50%, -50%) rotate(-9.5deg);
    }

    &[class*='--2'] {
      top: 60%;
      right: col(0.5, 10);
      left: initial;
      width: 20rem;
      transform: translate(50%, -50%) rotate(5deg);
    }

    &[class*='--3'] {
      top: 20%;
      right: initial;
      left: col(0.75, 10);
      width: 23rem;
      transform: translate(-50%, -50%) rotate(-19deg);
    }

    &[class*='--4'] {
      top: 50%;
      right: initial;
      left: col(0, 10);
      width: 27rem;
      transform: translate(-50%, -50%) rotate(11deg);
    }
  }

  @include mq(xl) {
    &[class*='--0'] {
      top: 10%;
      right: col(1, 10);
    }

    &[class*='--1'] {
      top: 30%;
      right: col(0.5, 10);
      left: initial;
      width: 27rem;
    }

    &[class*='--2'] {
      top: 55%;
      right: col(1.5, 10);
      left: initial;
      width: 25rem;
    }

    &[class*='--3'] {
      left: col(1.5, 10);
      width: 30rem;
    }

    &[class*='--4'] {
      top: 55%;
      left: col(1, 10);
    }
  }
}
